import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  import { useForm } from "react-hook-form";
  import { useNavigate } from "react-router-dom";
  import axiosInstance from "../../services/axios";
  import { ThemeToggler } from "../Theme/ThemeToggler";
  
  export const ResetPassword = () => {
    const {
      handleSubmit,
      register,
      formState: { errors, isSubmitting },
    } = useForm();
    const navigate = useNavigate();
    const toast = useToast();
  
    const onSubmit = async (values) => {
      try {
        await axiosInstance.post("/reset", values);
        toast({
          title: "Checke your email.",
          status: "success",
          isClosable: true,
          duration: 1500,
        });
        navigate("/login", { replace: true });
      } catch (err) {
        toast({
          title: `${err.response.data.detail}`,
          status: "error",
          isCloseable: true,
          duration: 1500,
        });
      }
    };
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Flex
          direction="column"
          alignItems="center"
          background={useColorModeValue("gray.100", "gray.700")}
          p={12}
          rounded={6}
        >
          <Heading mb={6}>Reset Password</Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.email}>
              <Input
                placeholder="Email"
                background={useColorModeValue("gray.300", "gray.600")}
                type="email"
                size="lg"
                mt={6}
                {...register("email", {
                  required: "This is required field",
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            
            <FormControl isInvalid={errors.email}>
              
            </FormControl>
            <Button
              isLoading={isSubmitting}
              loadingText="Creating account..."
              width="100%"
              colorScheme="green"
              variant="outline"
              mt={6}
              mb={6}
              type="submit"
            >
              Reset Password
            </Button>
          </form>
          <ThemeToggler showLabel={true} />
          
          <Button
          onClick={() => navigate("/register", { replace: true })}
          width="100%"
          colorScheme="gray"
          variant="outline"
          mt={6}
        >
          Register Instead
        </Button>
        <Button
          onClick={() => navigate("/login", { replace: true })}
          width="100%"
          colorScheme="gray"
          variant="outline"
          mt={6}
        >
          Login
        </Button>
        </Flex>
      </Flex>
    );
  };
  